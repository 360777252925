import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import Skills from "../components/Skills"


const SkillsPage = () => (
  <Layout>
    <Seo title="Projects" />
    <Skills/>
  </Layout>
)

export default SkillsPage
